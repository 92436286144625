import React from "react";
import "../../styles/gst.css";
import Testimonial from "../../components/Testimonial";
import Layout from "../../components/Layout";
import { Seo } from "../../components/seo";
import Counter from "../../components/Counter";
import FAQAccord from "../../components/GFlush";
import Guidance from "../../components/GSTTemp/Guidance";
// import Hero from "../../components/Hero";
import Cta from "../../components/Cta";
import ScrollNav from "../../components/GSTTemp/ScrollNav";
import ImgContent from "../../components/GSTTemp/ImgContent";
import ContentForm from "../../components/GSTTemp/ContentForm";
import ContentSlider from "../../components/GSTTemp/ContentSlider";
import AboutWithForm from "../../components/GSTTemp/AboutWithForm";
import Package from "../../components/GSTTemp/Package";

export default function ProfessionalTaxRegistration() {
  /* Slider */
  // const ProfessionalSlider = [
  //   {
  //     header_color: true,
  //     content_color: true,
  //     background: `linear-gradient(139.4deg, #0D324D, #7F5A83)`,
  //     header: `Professional Tax Registration`,
  //     content: `Apply Online Professional Tax Registration With TODAYFILINGS Experts`,
  //     image: "/imgs/registration/tax/tax-slider.png",
  //     alt_tag: "Best Online Professional Tax Registration Service in Hosur",
  //   },
  // ];

  /* Package Data */
  const PackageCompData = {
    header: `Professional Tax Registration`,
    buyBtnLink: `#pricing-buy`,
    price: `6999`,
    f_price: `9999`,
    content: [
      {
        points: `PAN & TAN Registration `,
        icon: '/imgs/icons/gst.png',
        alt_tag: 'PAN & TAN Registration',
        background: '',
        plus: '+',
      },
      {
        points: `PF + ESIC + Professional Tax`,
        icon: '/imgs/icons/itr_filing.png',
        alt_tag: 'TODAYFILINGS ITR E-filing service',
        background: 'bg-f78acb',
        plus: '+',
        plus_dnone: 'd-none d-md-block',
      },
      {
        points: `MSME Registration`,
        icon: '/imgs/icons/enterprises.png',
        alt_tag: 'MSME Registration',
        background: 'bg-eb6b3d',
        plus: '+',
      },
      {
        points: `Tax Consultancy Service `,
        icon: '/imgs/icons/consultant.png',
        alt_tag: 'Tax Consultancy Service',
        background: 'bg-c679e3',
      },
    ],
  };
  /* About Data With Form*/
  const ProAboutData = {
    header: `Professional Tax Registration Online`,
    sub_title: `Online Tax Registration With TODAYFILINGS Experts`,
    content_paragraph: [
      `In India, the State Government levies the Professional Tax on all salaried workers, including employees, professionals,
       and tradespeople. Online Tax Registration for Professionals in India For all employees working for a corporation, registering
        online is required. Every business or corporation must do this within a month of being registered or starting operations with
         the aid of Indian tax consultants.`,

      // `Employers and individuals involved in any profession or trade must register as professionals for tax purposes. A registration
      // certificate must be obtained by such organization.`,
    ],
  };

  /* Scroll Nav Data */
  const ProScrollId = [
    {
      id: `#procedure`,
      heading: `Procedure`,
    },
    {
      id: `#payment`,
      heading: `Payment & Penalty`,
    },
    {
      id: `#applicability`,
      heading: `Tax Applicability`,
    },
    {
      id: `#benefits`,
      heading: `Benefits`,
    },
  ];

  /* Img Content Component Data */
  const ProIcData = {
    id: 'procedure',
    mt_div: 'mt-5',
    mt_img: 'mt-1',
    header: 'Professional Tax Registration Process Online in India',
    image: '/imgs/registration/tax/tax-reg.png',
    alt_tag: 'professional tax registration',
    points: [
      {
        head: '',
        content: `Through the official CTD site, the applicant may submit an online application for a ptrc registration certificate.`,
        icon: true,
      },
      {
        head: '',
        content: `The application form should be sent along with all required scanned papers.`,
        icon: true,
      },
      {
        head: '',
        content: `A professional tax registration certificate application can also be made offline
        by delivering the required paperwork and application fee to the applicable State Government.`,
        icon: true,
      },
      {
        head: '',
        content: `The relevant Tax authority must examine each application carefully for verification after receiving it.`,
        icon: true,
      },
      {
        head: '',
        content: `Following the verification process, the Tax Authority must approve the application and issue the
        applicant with a certificate of professional tax registration.`,
        icon: true,
      },
      {
        head: '',
        content: `If there is a problem with the application, the Tax Department may ask a question, to which a response
         must be given within the allotted period.`,
        icon: true,
      },
    ],
  };

  /* Content Form Component Data */
  const ProCfData = {
    id: 'payment',
    mt_div: 'mt-5',
    mt_img: 'mt-5',
    header: 'Tax Payment and Penalty',
    content: ``,
    body: [
      {
        head: 'Payment of Professional Tax',
        points: `The Commercial Tax Department of that particular state debits professional tax at a predetermined slab
        rate either monthly or yearly.`,
        icon: true,
      },
      {
        head: '',
        points: `Self-employed people have the option of paying professional tax directly online, however employers are
        entirely responsible for withholding professional tax from employees' paychecks and remitting it to the relevant
         State Government in the case of salaried workers.`,
        icon: true,
      },
      {
        head: '',
        points: `The self-employed person must get the Certificate of Enrollment from the state's tax division.`,
        icon: true,
      },
      {
        head: '',
        points: `Duplicate of the
        lease if the place of business is rented property.`,
        icon: true,
      },
      {
        head: 'Consequences of Professional Tax Regulation Violation',
        points: `People who don't file their P tax return are subject to a penalty.
         All states impose fines on people who fail to register for professional taxes even after they become necessary.`,
        icon: true,
      },
      {
        head: '',
        points: `The fine for failing to pay the professional tax differs from state to state.
        A penalty of Rs. 5 per day is imposed for registering a tax enrollment certificate beyond the deadline.
        A penalty of 10% of the tax due is applied for failure to pay or for paying it late.`,
        icon: true,
      },
      {
        head: '',
        points: `An individual must pay a penalty of Rs. 1000 for filing returns late.
        After a month, the penalty will increase to Rs. 2000.`,
        icon: true,
      },
    ],
  };

  /* ImgSlider Data */
  const ProTaxRegImages = [
    '/imgs/registration/tax/tax-img-sliders.png',
    '/imgs/registration/tax/tax-imgslider.jpg',
    '/imgs/registration/tax/tax-img-slider.png',
  ];

  /* ContentSlider Component Data */
  const ProSCData = {
    id: 'applicability',
    background: [],
    mt_div: 'mt-3',
    header: 'Professional Tax Applicability',
    content: [
      {
        points: `Body of Individuals`,
        icon: true,
      },
      {
        points: `Association of Person`,
        icon: true,
      },
      {
        points: `Co-operative Society`,
        icon: true,
      },
      {
        points: `One person Company/Private/Public`,
        icon: true,
      },
      {
        points: `HUF`,
        icon: true,
      },
      {
        points: `Individual`,
        icon: true,
      },
    ],
    images: ProTaxRegImages,
  };

  /* Img Content Component Data */
  const ProsIcData = {
    id: 'benefits',
    mt_div: 'mt-5',
    background: 'main-content',
    mt_img: 'mt-1',
    header: 'Benefits Of Professional Tax Registration Online In India',
    image: '/imgs/registration/tax/tax-benefits.png',
    alt_tag: 'benefits for professional tax registration',
    points: [
      {
        head: '',
        content: `Self-employed person or employer should also pay their professional tax depending on their
        rates or wages provided by their respective state within the prescribed time.`,
        icon: true,
      },
      {
        head: '',
        content: `Paying a professional tax
        registration online in India is a judicial requirement and if any individual avoids it can be responsible
        for the penalty or legal issues.`,
        icon: true,
      },
      {
        head: '',
        content: `Professional tax is straightforward and simple to follow. Professional tax rules and regulations
        impose little limits, and registration is fairly straightforward with simple monthly or annual compliances.`,
        icon: true,
      },
      {
        head: '',
        content: `The deduction for previously paid professional taxes can be made.`,
        icon: true,
      },
      {
        head: '',
        content: `As a state tax, the professional tax service is responsible for ensuring that the professional
        tax is subtracted from professional fees, business income, and salaries.`,
        icon: true,
      },
    ],
  };

  var ProTaxRegFAQ = [
    {
      header: `What is a professional tax?`,
      body: [
        {
          content: `Drawing a regular income in the form of a salary or professional fees for your services requires
          you to pay a professional tax. Every professional has to pay this tax to their respective state governments
           based on their earnings. However, the percentage of deduction differs along with states in India, and your
           final professional tax amount is likely to vary based on this.`,
          icon: false,
        },
      ],
    },
    {
      header: `What is the registration certificate that is received by
      self-employed persons?`,
      body: [
        {
          content: `An individual should obtain a registration certificate from the
          relevant authority if their employer does not withhold professional
          tax from their wage.`,
          icon: false,
        },
      ],
    },
    {
      header: `Who should pay professional tax?`,
      body: [
        {
          content: `As mentioned earlier, if you are earning in India, you can pay professional tax. If you are employed,
           your employer makes a professional tax payment to the state government. If you are a professional with your
           practice, you need to make the professional tax payment on your own. The amount to be deducted depends on the
            slabs set out by every Indian state individually. However, the total amount of professional tax collected
             from you can never exceed Rs. 2,500.`,
          icon: false,
        },
      ],
    },
    {
      header: `Professional tax for salaried individuals`,
      body: [
        {
          content: `The professional tax is determined by the gross monthly income corresponding to the income slab, as
          per the state where you are employed. So, if your gross income every month is Rs. 30,000 and you are working
          in Maharashtra, you are liable to pay Rs. 200 every month except February. You will be required to pay Rs. 300
           only in February. This amount is derived based on the professional tax slab declared by the state. Your
           employer usually deducts this amount from your salary.`,
          icon: false,
        },
      ],
    },
    // 2
    {
      header: `Who is responsible to collect and deposit the professional tax to
      the government?`,
      body: [
        {
          content: `Different people are responsible for paying the professional tax;
          for example, a paid employee's employer is accountable, but a
          self-employed individual is accountable.`,
          icon: false,
        },
      ],
    },
    {
      header: `Is there any possibility to make a lump amount for professional tax
      liability?`,
      body: [
        {
          content: `A few states, like Maharashtra, have implemented the composition
          system, which allows anyone who owes professional tax of Rs. 2500 to
          pay the government even Rs. 10,000 and have their professional tax
          debt for the following five years cancelled.`,
          icon: false,
        },
      ],
    },
    {
      header: `Is it mandatory to pay professional tax?`,
      body: [
        {
          content: `Professional tax is a mandatory tax and there are penalties and fines in case of
          noncompliance. The penalties and fines in professional tax are levied for reasons like delay in
          registration, payment etc.`,
          icon: false,
        },
      ],
    },
    {
      header: `How is professional tax calculated?`,
      body: [
        {
          content: `Professional tax is calculated based on salary slabs. There is a minimum salary under which
          there is no professional tax. The maximum amount of professional tax that can be levied on a person is
          Rs. 2500 per year. Different states have different salary slab structures for levying professional tax.`,
          icon: false,
        },
      ],
    },
    {
      header: `How much professional tax is deducted from salary?`,
      body: [
        {
          content: `Professional tax is deductible from income prior to calculation of income tax, and hence appears
           at the top of the salary slip. It is deducted from the salary of the employee and deposited to the state
           government by the employer. `,
          icon: false,
        },
      ],
    },
    {
      header: `What happens if you don't pay professional tax?`,
      body: [
        {
          content: `As specified under Section 5(6) of the Profession Tax Act, the penalty you will incur if you are
          found providing wrong information when applying for an enrolment certificate or a registration certificate
          is three times the amount of your total tax obligation. Thus, it is imperative to be thorough with the
          application process so that you can make neat, professional tax transactions all along.`,
          icon: false,
        },
      ],
    },
  ];
  return (
    <div>
      <Seo
        title='Professional Tax Registration'
        keywords='Keywords'
        description='Description'
        name='TODAYFILINGS'
        type='Website'
        author='TODAYFILINGS'
      />

      <Layout>
        <main id='main'>
          {/* <Hero sliders={ProfessionalSlider} /> */}

          <Package packageData={PackageCompData} />

          <AboutWithForm about_content={ProAboutData} />

          <Counter />

          <ScrollNav scroll_data={ProScrollId} />

          <ImgContent item={ProIcData} />

          <ContentForm CFSection_data={ProCfData} />

          <ContentSlider CsSection_data={ProSCData} />

          <ImgContent item={ProsIcData} />
          <Cta />

          <Guidance />

          <FAQAccord items={ProTaxRegFAQ} />

          <Testimonial />
        </main>
      </Layout>
    </div>
  );
}
